<template>

    <div>
        <head>
          
        <!--- Please place in header --->
        <!--- Start: GPT header script --->

        <script async src='https://securepubads.g.doubleclick.net/tag/js/gpt.js' type="application/javascript"></script>
        <script type="application/javascript">
          window.googletag = window.googletag || {cmd: []};
          googletag.cmd.push(function() {
            googletag.pubads().setTargeting('breakHcap','TBC');
            googletag.pubads().setTargeting('CupBestScr','TBC');
            googletag.pubads().setTargeting('FreqOfPlay','TBC');
            googletag.pubads().setTargeting('refdomain','TBC');
            googletag.pubads().setTargeting('ClubGroup','TBC');
            googletag.pubads().setTargeting('abID','TBC');
            googletag.pubads().setTargeting('State','TBC');
            googletag.pubads().setTargeting('Clubid','TBC');
            googletag.pubads().setTargeting('ClubName','TBC');
            googletag.pubads().setTargeting('Country','TBC');
            googletag.pubads().setTargeting('ExactHcap','TBC');
            googletag.pubads().setTargeting('Gender','TBC');
            googletag.pubads().setTargeting('GLNo','TBC');
            googletag.pubads().setTargeting('LocId','TBC');
            googletag.pubads().setTargeting('pagetitle','TBC');
            googletag.pubads().setTargeting('sitesectop','TBC');
            //googletag.pubads().enableLazyLoad({
                //fetchMarginPercent: 200,
                //renderMarginPercent: 25,
                //mobileScaling: 2.0
              //});
            googletag.enableServices();
          });
        </script>
          
        <!--- End: GPT header script --->
        </head>
        <b-col class="hidebelow1200">
          <script async src="https://securepubads.g.doubleclick.net/tag/js/gpt.js" type="application/javascript"></script>
          <script type="application/javascript">
            window.googletag = window.googletag || {cmd: []};
            googletag.cmd.push(function() {
            billboardsizes = googletag.sizeMapping()
            .addSize([980,690],[300,250])
            .addSize([0,0],[320,50])
            .build();
            googletag.defineSlot('/27436116/Golf_PGA_Leaderboard_Top', [[320, 50], [300, 250]], 'leaderboardtop').defineSizeMapping(billboardsizes).addService(googletag.pubads());
            googletag.pubads().enableSingleRequest();
            googletag.enableServices();
          });
          </script>

          <!-- /2835490/New_PGA_Top_MREC -->
          <div id='leaderboardtop' style="margin: auto; display:block; text-align: center">
          <script type="application/javascript">
            googletag.cmd.push(function() { googletag.display('leaderboardtop'); });
          </script>
          </div>
        <p><br></p>
        <div align="center" style="max-width: 670px; margin-right: auto; margin-left: auto; justify-content: center; align-items: center; font-size: 14px">
        <p>Taciti morbi sed ipsum ultricies nostra nullam nec, purus ullamcorper dapibus enim a facilisi sollicitudin dictum mauris non eleifend nostra bibendum dolor ac nisl.</p>

        <p>Ad dapibus dictum vivamus. Nibh quam leo porttitor est id vulputate commodo malesuada justo dolor velit faucibus viverra metus sociis donec tellus egestas. Semper torquent scelerisque pede risus nullam.</p>
        </div>
        </b-col>
            
            <script type="application/javascript">
              window.googletag = window.googletag || { cmd: [] };
              const responsiveAdSlot = googletag

              googletag.cmd.push(function() {
                  var mappingLeaderboard = googletag.sizeMapping().addSize([980, 0], [[970, 90],[728, 90]]).addSize([0, 0], [320, 50]).build();
                  googletag.defineSlot('/27436116/Golf_PGA_Leaderboard_Tournament_Pos5',[[320,50],[728,90],[970,90]], 'leaderboardpos5')
                      .defineSizeMapping(mappingLeaderboard)
                      .setTargeting('pos',['leaderboardpos5'])
                      .addService(googletag.pubads())
                  googletag.pubads().setCentering(true);
                  googletag.enableServices();
                  googletag.display('leaderboardpos5');
              })
            </script>
            <div class="ad-container" style="margin: auto; display:block; text-align: center">
              <div id="leaderboardpos5" class="ad-slot"></div>
            </div>
            <script async src="https://securepubads.g.doubleclick.net/tag/js/gpt.js" type="application/javascript"></script>
            <script type="application/javascript">
              window.googletag = window.googletag || {cmd: []};
              googletag.cmd.push(function() {
              billboardsizes = googletag.sizeMapping()
              .addSize([980,690],[728,90])
              .addSize([0,0],[320,50])
              .build();
              googletag.defineSlot('/27436116/Golf_PGA_Leaderboard_Tournament_Pos5', [[970, 250], [728, 90],  [320, 50],], 'leaderboardpos5').defineSizeMapping(billboardsizes).addService(googletag.pubads());
              googletag.pubads().enableSingleRequest();
              googletag.enableServices();
              });
            </script>

            <div id='leaderboardpos5' class="lazy">
              <div>
                <script type="application/javascript">
                  googletag.cmd.push(function() { googletag.display('leaderboardpos5'); });
                </script>
              </div>
            </div>
        <p><br></p>
        <div align="center" style="max-width: 670px; margin-right: auto; margin-left: auto; justify-content: center; align-items: center; font-size: 14px">
        <p>Second Paragraph</p>

        <p>Second Paragraph, Second Paragraph</p>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
  name: "about",
  props:['slug', 'config'],
  components: {
  },
  data() {
    return {
      wp: [],
      Links: [],
    };
  },
    computed: {
    routeSlug: function(route) {
      if (this.slug === undefined) {
        route = this.$route.name;
      } else {
        route = this.slug;
      }
      return route;
    },
  },
  mounted() {
    axios
      .get(process.env.VUE_APP_WPAPI_URL+"wp/v2/pages/?slug=" + this.routeSlug)
      .then((response) => {
        this.wp = response.data;
        return axios.get(
          process.env.VUE_APP_WPAPI_URL+"wp/v2/" + this.routeSlug + "/"
        );
      })
      .then((response) => {
        this.Links = response.data;
      });
  },
};
</script>
<style scoped>
::v-deep img.wp-image-29311 {
  text-align: center;
  margin: auto;
  display: block;
}
a.aLinkmail {
  color: #000!important;
}
a.aLinkmail:hover {
  color: #00b588!important;
}
.awardsParagraph {
  color: #000;
}
::v-deep .learnA > a {
  padding-left: 0px;
}
::v-deep .learnA > a > button.btn.btn-secondary{
  padding-top: 10px;
  padding-bottom: 10px;
  background: #01285e;
  border: #01285e;
}
::v-deep .learnA > a > button.btn.btn-secondary:hover{
  padding-top: 10px;
  padding-bottom: 10px;
  background: #2d70c3;
  border: #2d70c3;
  color: #fff;
}
::v-deep .awardstabs > div > h4.card-title{
  color: #01285e;
}
::v-deep .awardstabs > .card-body{
  height: 320px;
  padding-top: 3em;
}
::v-deep .awardscard {
  margin-bottom: 30px;
}
::v-deep .mediaCentre > div > h4 {
  margin-bottom: 0;
  margin-top: 0.8em;
}
::v-deep button.btn.all-butn.btn-secondary {
  background: #01285e;
  border: #01285e;
  color: #fff;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  margin-top: -2em;
  margin-left: -0.9em;
  padding-top: 14px;
}
::v-deep p.job-important {
  margin-bottom: 10px;
}
::v-deep .card-body-non-play {
  padding-top: 30px;
  padding-left: 10px;
  padding-bottom: 10px;
}
@media (min-width: 768px) {
  ::v-deep #partners a {
    flex: 0 1 24%;
    padding: 2.5em;
  }
}
.closeBtn:hover {
  position: absolute;
  top: -20px;
  right: -20px;
  background: #04b488;
  color: #fff;
  border: 1px solid #000;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 20px;
  font-weight: bold;
  cursor: pointer;
}
.closeBtn {
  position: absolute;
  top: -20px;
  right: -20px;
  background: #fff;
  color: #04b488;
  border: 1px solid #000;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 20px;
  font-weight: bold;
  cursor: pointer;
}
::v-deep h5.cardTitle.posonCard {
  font-size: 17px;
}
::v-deep h5.cardTitle.nameonCard {
  color: #01285e;
  font-weight: 700;
  font-size: 15pt;
}
::v-deep span.descriptModal {
  font-size: 17px;
}
::v-deep img.wp-image-11471 {
  max-width: 100%;
  text-align: center;
  margin: auto;
  display: block;
}
#about {
  background-color: #f9f9f9;
}
.titleCont {
  position: relative;
  top: 49%;
  transform: translateY(-50%);
  text-align: center;
}
.splash {
  text-align: center;
  color: white;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  max-height: 550px;
  height: 275px;
}
.mainContent {
  padding: 100px 0;
}
.box {
  display: block;
  top: 0px;
  border-radius: 4px;
  position: relative;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
}

.box:hover {
  transition: all 0.3s ease;
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
  top: -4px;
  background-color: white;
  /* color: #00b588; */
  cursor: pointer;
}
.cardTitle {
  padding: 2.5px 0;
}
.cardEmail {
  padding: 10px 0;
}
#aboutCard {
  padding: 45px 0;
}
.govRow {
  padding: 10px;
}
.btn {
  background-color: #00433e;
  border: 1px solid #00433e;
}
.btn:hover {
  background-color: white;
  color: #00433e;
  border: 1px solid #00433e;
}
.modName{
    color: #00433e;
    font-weight: 700;
    font-size: 25pt;
    margin-top: 0;
}
.modPos{
    color: #00b588;
    font-size: 15pt;
    font-weight: 700;
}
.modApp{
      color: #00b588;
    font-size: 15pt;
    font-weight: 700;
}
::v-deep .modal-body{
  padding: 20px;
}
@media only screen and (max-width: 768px) {
  .mainContent {
    padding: 15px;
  }
}
@media (min-width: 768px){
  ::v-deep .modal-dialog{
    width: 700px!important;
    max-width: 100%;
  }
}
</style>
